import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { AuthService, ResourceService } from "../../services";
import { Button } from "react-bootstrap";
import ReactPaginate from 'react-paginate';

const ResourcesList = () => {
  const navigate = useNavigate();
  const [resources, setResources] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [currentItems, setCurrentItems] = useState(null);
  const [sorting, setSorting] = useState({key: '', order: ''});
	// Here we use item offsets; we could also use page offsets
	// following the API or data you're working with.
	const [itemOffset, setItemOffset] = useState(0);
	const [pageCount, setPageCount] = useState(0);
	const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showDeletedItems, setShowDeletedItems] = useState(false);
  
  useEffect(() => {
    if (!AuthService.canAccessLegacySystem()) {
      window.alert('You haven\'t login yet OR this user does not have access to this page. Please change an admin account to login.')
      AuthService.logout();
      navigate(`/login`);
    }
    ResourceService.getAll().then(data => {
      setResources(data.data);
    }) 
  }, []);

  useEffect(() => {
		const endOffset = itemOffset + parseInt(itemsPerPage);
    const newResources = showDeletedItems ? resources : resources.filter(item => item.status === 'active')?.filter(resource => (resource?.name?.toLowerCase().includes(keyword.toLowerCase()) || (resource?.specialty?.toLowerCase().includes(keyword.toLowerCase()) ) || (resource?.address?.toLowerCase().includes(keyword.toLowerCase()) )));
    //const newResources = (.slice(itemOffset, endOffset);
    const sortedResources = sorting.key === '' ? newResources : newResources.sort((a, b) => {
      return a[sorting.key]?.localeCompare(b[sorting.key]);
    });
    const results = sorting.order === 'asc' ? sortedResources : sortedResources.reverse();
		setCurrentItems(results.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(((showDeletedItems ? resources : resources.filter(item => item.status === 'active') )?.filter(resource => (resource?.name?.toLowerCase().includes(keyword.toLowerCase()) || (resource?.specialty?.toLowerCase().includes(keyword.toLowerCase()) ) || (resource?.address?.toLowerCase().includes(keyword.toLowerCase()) )))).length / itemsPerPage));
	}, [resources, itemOffset, keyword, itemsPerPage, showDeletedItems, sorting]);

  // useEffect(() => {
  //   const newResources = currentItems? [...currentItems] : [];
  //   console.log('here', sorting);
    
  //   setCurrentItems(
  //     sorting.order === 'asc' ? sortedResources : sortedResources.reverse()
  //   )
  // }, [sorting]);

	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % (showDeletedItems ? resources : resources.filter(item => item.status === 'active') )?.filter(resource => (resource?.name?.toLowerCase().includes(keyword.toLowerCase()) || (resource?.specialty?.toLowerCase().includes(keyword.toLowerCase()) ) || (resource?.address?.toLowerCase().includes(keyword.toLowerCase()) ))).length;
		console.log(
			`User requested page number ${event.selected}, which is offset ${newOffset}`
		);
		setItemOffset(newOffset);
	};

  const getSortingImg = (key) => {
    return sorting.key === key ? (sorting.order === 'asc' ? 'up_arrow' : 'down_arrow') : 'default';
  }

  const sortTableWithField = (key) => {
    let newSorting = {
      key,
      order: 'asc',
    }
    
    if (sorting.key === key && sorting.order === 'asc') {
      newSorting = {...newSorting, order: 'desc'};
    }
    setSorting(newSorting);
  }

  const redirectToAdmin = () => {
    navigate(`/medical`)
  }

  const goToEdit = (id) => {
    navigate(`/medical/resources/edit/${id}`)
  }

  const goToCreateNew = () => {
    navigate(`/medical/resources`)
  }

  const goToView = (id) => {
    navigate(`/medical/resources/${id}`)
  }

  const deleteResource = (id) => {
    const currentResource = resources.find((item) => item.id === id);
    ResourceService.disableResource(id, { status: 'inactive', edit_by: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name,
    edit_date: new Date(),
    edit_history: currentResource?.edit_history? [...currentResource.edit_history, { employee: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name, date: new Date() }] : [{ employee: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name, date: new Date() }]}).then(() => {
      ResourceService.getAll().then(data => {
        setResources(data.data);
      }) 
    });
  }
  
  return (
    <>
      <div className="list row mb-4">
        <div className="col-md-12 text-primary">
           <h5>Resources List  <button className="btn btn-primary btn-sm" onClick={() => {goToCreateNew()}}>Create New Resource</button> <button className="btn btn-link btn-sm" onClick={() => {redirectToAdmin()}}>Back</button></h5> 
        </div>
      </div>
      <div className="list row mb-4">
        <div className="col-md-12 col-sm-12 mb-4">
          Search By Keywords: <input type="text" value={keyword} onChange={e => setKeyword(e.target.value)} />
        </div>
        <div className="col-md-12 col-sm-12 mb-4">
           Show Deleted Events: <input type="checkbox" value={showDeletedItems} checked={showDeletedItems === true} onClick={() => setShowDeletedItems(!showDeletedItems)} />
        </div>
        <div className="col-md-12 col-sm-12 mb-4">
          Items Per Page: <select value={itemsPerPage} onChange={e => setItemsPerPage(e.target.value)}>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <div className="col-md-12">
          <table className="personnel-info-table mb-4 medical"> 
            <thead>
              <tr className="sortable-header">
                <th>Name  <span onClick={() => sortTableWithField('name')}><img src={`/images/${getSortingImg('name')}.png`}></img></span></th>
                <th>Specialty  <span onClick={() => sortTableWithField('specialty')}><img src={`/images/${getSortingImg('specialty')}.png`}></img></span></th>
                <th>Address</th>
                <th>Office Name</th>
                <th>Branch Name</th>
                <th>City</th>
                <th>State</th>
                <th>Zipcode</th>
                <th></th>
              </tr>
              
            </thead>
            <tbody>
              {
                currentItems?.map(resource => <tr key={resource.id}>
                  <td>{`${resource?.name || ''}${resource?.name ? '-': ''}${resource?.name_original || ''}${resource?.name_original ? '-': ''}${resource?.name_branch || ''}`}</td>
                  <td>{resource?.specialty}</td>
                  <td>{resource?.address}</td>
                  <td>{resource?.name_original}</td>
                  <td>{resource?.name_branch}</td>
                  <td>{resource?.city}</td>
                  <td>{resource?.state}</td>
                  <td>{resource?.zipcode}</td>
                  <td style={{'min-width': '220px'}}><Button variant="primary" className="me-2" size="sm" onClick={() => goToEdit(resource?.id)}>Edit</Button> <Button variant="secondary" size="sm" onClick={() => goToView(resource?.id)} className="me-2">View</Button> <Button size="sm" variant="danger" onClick={() => deleteResource(resource?.id)}>Delete</Button></td>
                </tr>)
              }
            </tbody>
          </table>
          <ReactPaginate
            key={itemsPerPage}
            className="customers-pagination"
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName="pagination justify-content-center"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active"
            breakClassName="page-item"
            breakLinkClassName="page-link"
          />
           
        </div>
      </div>
     
    </>
  )
};

export default ResourcesList;