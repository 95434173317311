import React, {useState, useEffect} from "react";
// import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
// import { customerSlice } from "./../../store";
import { AuthService, CustomerService, EventsService, ResourceService } from "../../services";
import Select from 'react-select';
import { Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";

const UpdateEvent = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const [currentEvent, setCurrentEvent] =  useState(undefined);
	const [medicalResource, setMedicalResource] = useState(undefined);
  const [currentResource, setCurrentResource] = useState(undefined);
  const [resources, setResources] = useState([]);
  const [filteredResources, setFilteredResources] = useState([]);
  const [resourceType, setResourceType] = useState('');
  const [customers, setCustomers] = useState([]);
  const [customerName, setCustomerName] = useState(undefined);
  const [currentCustomer, setCurrentCustomer] = useState(undefined);
  const [startTime, setStartTime] = useState('');
  const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
  const [newPatient, setNewPatient] = useState('');
  const [confirmed, setConfirmed] = useState('');
  const [fasting, setFasting] = useState('');
  const [interpreter, setInterpreter] = useState('');
  const [docOrder, setDocOrder] = useState('');
  const [disability, setDisability] = useState('');
  const [needId, setNeedId] = useState('');
  const [needMedicationList, setNeedMedicationList] = useState('');
  const [disabilitySupport, setDisabilitySupport] = useState('');
  const [notes, setNotes] = useState('');
  const [color, setColor] = useState('');
  const [reason, setReason] = useState('');
  const [otherRequirement, setOtherRequirement] = useState('');
  const [todayEvents, setTodayEvents] = useState([]);
  const [transportationInfo, setTransportationInfo] = useState(undefined);
  const [hideCreateFields, setHideCreateFields] = useState(false);
  const [timeData, setTimeData] = useState([]);
  const [transMethod, setTransMethod] = useState('');
  const [showResourceModal, setShowResourceModal] = useState(false);
  const [keyword, setKeyword] = useState('');
  
  const isSite3 = window.location.hostname.includes('worldshine3.mayo.llc') || window.location.hostname.includes('site3');
  const params = new URLSearchParams(window.location.search);
  const redirectToCalendar = () => {
		navigate(`/medical/events/calendar`);
  }

  const redirectTo = (id) => {
    if (params.get('from') === 'calendar') {
      redirectToCalendar()
    } else {
      if (params.get('from') === 'create') {
        redirectToCalendar()
      } else {
        if (params.get('event')) {
          redirectToCalendar()
        } else {
          navigate(`/medical/events/list`);
        }
      }
    }
  }

  const redirectToView = () => {
		navigate(`/medical/events/${urlParams.id}`);
  }

  const saveEvent = () => {
    console.log('customer', currentCustomer);
    console.log('resource', currentResource);

    let newEventDataClientAndResource = {};
    let newEventClientAndResource = {};
    if (currentCustomer) {
      newEventDataClientAndResource = Object.assign({}, newEventDataClientAndResource, {
        customer: currentCustomer.id,
        client_name: currentCustomer.name,
        client_pharmacy: currentCustomer.pharmacy,
        client_pcp: currentCustomer.care_provider,
        client_birth_date: currentCustomer.birth_date,
        client_seating: currentCustomer.seating
      });
      newEventClientAndResource = Object.assign({}, newEventClientAndResource, {
        target_type: currentCustomer.type,
        target_uuid: currentCustomer.id,
        target_name: currentCustomer.name
      })
    }
    if (currentResource) {
      newEventDataClientAndResource = Object.assign({}, newEventDataClientAndResource, {
        resource: currentResource.id,
        resource_type: currentResource.type,
        resource_name: currentResource.name,
        resource_phone: currentResource.phone,
        resource_contact: currentResource.contact,
        resource_address: currentResource.address,
        resource_city: currentResource.city,
        resource_state: currentResource.state,
      });
      newEventClientAndResource = Object.assign({}, newEventClientAndResource, {
        source_type: currentResource.type,
        source_uuid: currentResource.id,
        source_name: currentResource.name,
      })
    }
    
    const newEventData = {
      ...currentEvent.data,
      // legacy fields end
      // We still wanna keep the legacy fields below
      new_patient: newPatient,
      confirmed: confirmed,
      fasting: fasting,
      interpreter: interpreter,
      doc_order: docOrder,
      need_id: needId,
      need_med_list: needMedicationList,
      notes: notes,
      reason: reason,
      other: otherRequirement,
      disability: disability,
      disability_support: disabilitySupport,
      trans_method: transMethod,
      ...newEventDataClientAndResource
    }
    
    const newEvent = {
      ...currentEvent,
      data: newEventData,
      title: title,
      descrption: description,
      color: color,
      notes: notes,
      start_time: new Date(startTime),
      stop_time: new Date(startTime),
      edit_by: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name,
      edit_date: new Date(),
      edit_history: currentEvent?.edit_history? [...currentEvent.edit_history, { employee: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name, date: new Date() }] : [{ employee: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name, date: new Date() }],
      ...newEventClientAndResource
    }

    console.log('new Event', newEvent);
    if (params.get('event')) {
      if (currentCustomer && currentResource)
      EventsService.createNewEvent(newEvent).then(data => redirectTo());
    } else {
      if (params.get('from') === 'create') {
        if (currentCustomer && currentResource) {
          EventsService.updateEvent(urlParams.id, newEvent).then(data => redirectTo());
        }
      } else {
        EventsService.updateEvent(urlParams.id, newEvent).then(data => redirectTo());
      }
    }
  };

  const filterResourceListByKeyword = (item) => {
    if (keyword.length > 0) {
      const newKeyword = keyword.toLowerCase();
      return item?.name.toLowerCase()?.includes(newKeyword) || item?.address?.toLowerCase()?.includes(newKeyword) || item?.specialty?.toLowerCase()?.includes(newKeyword);
    } else {
      return true;
    }
  }

  const filterResourcesListBySpecialty = (item) => {
    if (resourceType.length > 0) {
      return item?.type === resourceType;
    } else {
      return true;
    }
  }
  

  useEffect(() => {
    if (!AuthService.canAccessLegacySystem()) {
      window.alert('You haven\'t login yet OR this user does not have access to this page. Please change an admin account to login.')
      AuthService.logout();
      navigate(`/login`);
    }
    if (params.get('from') === 'create') {
      setHideCreateFields(true);
    }
    Promise.all([ResourceService.getAll(), CustomerService.getAllCustomers(), EventsService.getTimeData()]).then(([resourcesData, customersData, timeDatas]) => {
      setResources(resourcesData.data);
      setFilteredResources(resourcesData.data);
      setCustomers(customersData.data);
      setTimeData(timeDatas.data);
      if (!currentEvent) {
        if (params.get('event')) {
          console.log('event', JSON.parse(params.get('event')));
          setCurrentEvent(JSON.parse(params.get('event')));
        } else {
          EventsService.getEvent(urlParams.id).then(eventData => {
            setCurrentEvent(eventData.data);
            EventsService.getAllEvents({date: EventsService.formatDate(new Date(eventData.data.start_time))}).then(eventsData => {
              setTodayEvents(eventsData.data)
              const targetedEvent = eventsData.data.find(item => item.id === urlParams.id)
              setTransportationInfo(EventsService.getTransportationInfo(eventsData.data, targetedEvent, timeData));
            })
          })
        }
        
        
      }
    })
  }, []);

  useEffect(() => {
    const newResources = [...resources];
    
    if (resourceType && resourceType !== '') {
      setFilteredResources(newResources?.filter(item => item.type === resourceType));
    }
  }, [resourceType])

  const onCustomerChange = (selectedCustomer) => {
    setCustomerName(selectedCustomer);
    setCurrentCustomer(customers.find(customer => customer.id === selectedCustomer.value))
  }

  const onResourceChange = (selectedResource) => {
    setMedicalResource(selectedResource);
    setCurrentResource(resources.find(resource => resource.id === selectedResource.value));
  }

  

  useEffect(() => {
    if (currentEvent) {
      if (!currentEvent.data.customer) {
        CustomerService.getClient(currentEvent.target_uuid).then((data) => {
          const legacyClient = data?.data;
          const mappingCustomer = customers.find((item) => item.email === legacyClient.email && item.username === legacyClient.username);
          setCustomerName({value: mappingCustomer.id, label: mappingCustomer.name});
          setCurrentCustomer(mappingCustomer);
          
        });
      } else {
        setCustomerName({value: currentEvent.data.customer, label: customers.find(item => item.id === currentEvent.data.customer)?.name});
        setCurrentCustomer(customers.find(item => item.id === currentEvent.data.customer));
      }
      setMedicalResource({ value: currentEvent.data.resource || currentEvent.source_uuid, label: resources.find(item => item.id === currentEvent.source_uuid)?.name });
      setCurrentResource(resources.find(item => item.id === currentEvent.source_uuid));
      setStartTime(new Date(currentEvent.start_time));
      setTitle(currentEvent.title);
      setColor(currentEvent.color);
      setDescription(currentEvent.description);
      setNewPatient(currentEvent.data.new_patient);
      setConfirmed(currentEvent.data.confirmed);
      setFasting(currentEvent.data.fasting);
      setInterpreter(currentEvent.data.interpreter);
      setDocOrder(currentEvent.data.doc_order);
      setDisability(currentCustomer?.disability ? 'yes' : (currentEvent.data.disability));
      setDisabilitySupport(currentEvent.data.disability_support);
      setNeedId(currentEvent.data.need_id);
      setNeedMedicationList(currentEvent.data.need_med_list);
      setNotes(currentEvent.data.notes);
      setReason(currentEvent.data.reason);
      setOtherRequirement(currentEvent.data.other);
      setTransMethod(currentEvent?.data?.trans_method);
    }
    
  }, [currentEvent]);

  return (
    <>
      <div className="list row">
        <div className="col-md-8 col-xs-12">
          <div className="list row mb-4">
            <div className="col-md-12 text-primary">
              <h5>{ params.get('event') ? 'Create Medical Event from Request' : 'Update Medical Event'} <button className="btn btn-link btn-sm" onClick={() => {redirectTo()}}>Back</button></h5> 
            </div>
          </div>
          <div className="list row mb-4">
            <div className="col-md-6 mb-4">
              <div>Resource:</div>
              {currentResource ? 
                (<><span>{currentResource?.name}</span> <span><button className="btn btn-link btn-sm me-2 mb-2" onClick={()=> setShowResourceModal(true)}>Update</button></span></>)  : 
                (currentEvent?.data?.resource_name ? <><span>{currentEvent?.data?.resource_name}</span> <span><button className="btn btn-link btn-sm me-2 mb-2" onClick={()=> setShowResourceModal(true)}>Update</button></span></> : <button className="btn btn-link btn-sm me-2 mb-2" onClick={()=> setShowResourceModal(true)}>Please click here to add resources</button>)}
              {/* <div>You can select a Resource type to filter the resources you can select from:</div>
              <select value={resourceType} onChange={e => setResourceType(e.target.value)}>
                <option value=""></option>
                <option value="doctor">Doctor</option>
                <option value="pharmacy">Pharmacy</option>
                <option value="hospital">Hospital</option>
                <option value="surgical center">Surgical Center</option>
                <option value="government agency">Government Agency</option>
                <option value="other">Other</option>
              </select>  */}
            </div>
            {/* <div className="col-md-6 mb-4">
            <div>Resource:</div>
              <Select value={medicalResource || ''} onChange={selectedResource => onResourceChange(selectedResource)} options={[{value: '', label: ''}, ...filteredResources.map(resource => ({
                value: resource.id || '',
                label: resource?.name || ''
              }))]}></Select>
            </div> */}
            {!hideCreateFields && <div className="col-md-6 mb-4">
              <div>Client:</div>
             
              <Select value={customerName || ''} onChange={selectedCustomer => onCustomerChange(selectedCustomer)} options={[{value: '', label: ''}, ...customers.map(customer => ({
                value: customer?.id || '',
                label: customer?.name || ''
              }))]}></Select>
            </div>}
            <div className="col-md-6 mb-4">
              <div>Title:</div> <input type="text" value={title || ''} onChange={e => setTitle(e.target.value)}/>
            </div>
            <div className="col-md-6 mb-4">
              <div>Start Time:</div> <DatePicker
                selected={startTime}
                onChange={setStartTime}
                showTimeInput
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy, HH:mm"
              ></DatePicker>
              {/* <div>Start Time</div> <DateTimePicker format={'MM/dd/y H:m'} disableClock={true}  value={startTime} onChange={setStartTime} /> */}
            </div>
            <div className="col-md-12"></div>
            {/* <div className="col-md-12 mb-4">
              <div>Description:</div> <textarea value={description || ''} onChange={e => setDescription(e.target.value)}/>
            </div> */}
            <div className="col-md-4 mb-4">
              <div>Color:</div>
              <select value={color} onChange={e => setColor(e.target.value)}>
                <option value=""></option>
                <option value="red">Red</option>
                <option value="brown">Brown</option>
                <option value="green">Green</option>
                <option value="blue">Blue</option>
                <option value="black">Black</option>
                <option value="purple">Purple</option>
                <option value="gray">Gray</option>
                <option value="orange">Orange</option>
                <option value="pink">Pink</option>
              </select>
            </div>
            <div className="col-md-4 mb-4">
              <div>New Patient:</div>
              <select value={newPatient} onChange={e => setNewPatient(e.target.value)}>
                <option value=""></option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div className="col-md-4 mb-4">
              <div>Confirmed:</div> 
              <select value={confirmed} onChange={e => setConfirmed(e.target.value)}>
                <option value=""></option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div className="col-md-4 mb-4">
              <div>Fasting:</div>
              <select value={fasting} onChange={e => setFasting(e.target.value)}>
                <option value=""></option>
                <option value="empty_stomach">Empty Stomach</option>
                <option value="clear_liquid">Clear Liquid</option>
              </select>
            </div>
            <div className="col-md-4 mb-4">
              <div>Interpreter Level:</div>
              <select value={interpreter} onChange={e => setInterpreter(e.target.value)}>
                <option value=""></option>
                <option value="1(译)">1(译)</option>
                <option value="2(译)">2(译)</option>
                <option value={isSite3 ? 'Family' : "家(译)"}>{isSite3 ? 'Family' : "家(译)"}</option>
                <option value="越南语翻译">越南语翻译</option>
                <option value="Office">Office</option>
                <option value="Office(Phone)">Office(Phone)</option>
                {isSite3 && <option value="Center">Center</option>}
              </select>
            </div>
            <div className="col-md-4 mb-4">
              <div>Doctor Order:</div> 
              <select value={docOrder} onChange={e => setDocOrder(e.target.value)}>
                <option value=""></option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div className="col-md-4 mb-4">
              <div>Disability: </div>
              <select value={disability} onChange={e => setDisability(e.target.value)}>
                <option value=""></option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div className="col-md-4 mb-4">
              <div>Need Id: </div>
              <select value={needId} onChange={e => setNeedId(e.target.value)}>
                <option value=""></option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div className="col-md-4 mb-4">
              <div>Need Medication List</div>
              <select value={needMedicationList} onChange={e => setNeedMedicationList(e.target.value)}>
                <option value=""></option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div className="col-md-4 mb-4">
              <div>Disability Support:</div>
              <select value={disabilitySupport} onChange={e => setDisabilitySupport(e.target.value)}>
                <option value=""></option>
                <option value="center worker">center worker</option>
                <option value="family member">family member</option>
              </select>
            </div>
            <div className="col-md-4 mb-4">
              <div>Transportation:</div>
              <select value={transMethod} onChange={e => setTransMethod(e.target.value)}>
                <option value=""></option>
                <option value="by own">By Own</option>
                <option value="televisit">Televisit</option>
                <option value="pickup only">Pickup Only</option>
                <option value="dropoff only">Dropoff Only</option>
                <option value="client does not need to go">Client Does Not Need To Go</option>
              </select>
            </div>
            <div className="col-md-4 mb-4">
              <div>Notes:</div><textarea value={notes || ''} onChange={e => setNotes(e.target.value)}/> 
            </div>
            <div className="col-md-4 mb-4">
              <div>Reason:</div> 
              <textarea value={reason || ''} onChange={e => setReason(e.target.value)}/>
            </div>
            <div className="col-md-4 mb-4">
              <div>Other Requirements:</div>
              <textarea value={otherRequirement || ''} onChange={e => setOtherRequirement(e.target.value)}/>
            </div>
          </div>
          <div className="list row mb-5">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <button className="btn btn-primary btn-sm me-2 mb-2" onClick={() => saveEvent()}> Save </button>
              <button className="btn btn-default btn-sm" onClick={() => redirectTo()}> Cancel </button>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-xs-12">
          <fieldset>
            <legend>Resource Info</legend>
            <p>{currentResource?.name || currentEvent?.data?.resource_name || ''}</p>
            {currentResource?.specialty && (<p>{currentResource?.specialty || ''}</p>)}
            <p>{currentResource?.phone || currentEvent?.data?.resource_phone || ''}</p>
            <p>{currentResource?.contact || currentEvent?.data?.resource_contact || ''}</p>
            <p>{currentResource?.address || currentEvent?.data?.resource_address || ''}</p>
          </fieldset> 

          <p></p>

          <fieldset>
            <legend>Client Info</legend>
            <p>{currentCustomer?.name || currentEvent?.data?.client_name || ''}</p>
            {currentCustomer?.name_cn && (<p>{currentCustomer?.name_cn || ''}</p>)}
            <p>{currentCustomer?.pharmacy || currentEvent?.data?.client_pharmacy || ''}</p>
            <p>{currentCustomer?.care_provider || currentEvent?.data?.client_pcp || ''}</p>
            <p>{currentCustomer?.birth_date || currentEvent?.data?.client_birth_date ||''}</p>
          </fieldset> 

          <p></p>

          {transportationInfo?.isFutureEvent && <fieldset>
            <legend>Transportation Info</legend>
            <p>translate1: {transportationInfo?.maxTranslate1 || 0}</p>
            <p>translate2: {transportationInfo?.maxTranslate1 || 0}</p>
            <p>max resource: {transportationInfo?.maxResource || 0}</p>
            <p></p>

            <h4 style={{'color': 'red'}}>Remain:</h4>
            <p>(when edit, the numbers below are for reference only)</p>
            <p>translate1: { transportationInfo?.maxTranslate1 - transportationInfo?.totalTranslate1  - (currentEvent?.data?.interpreter === '1(译)' ? 0 : (interpreter==='1(译)' ? 1 : 0)) }</p>
            <p>translate2: { transportationInfo?.maxTranslate2 - transportationInfo?.totalTranslate2 - (currentEvent?.data?.interpreter === '2(译)' ? 0 : (interpreter==='2(译)' ? 1 : 0)) }</p>
            <p>resources: {transportationInfo?.maxResource - transportationInfo?.totalResource || 0 }</p>
          </fieldset> }
        </div>
      </div>
      <Modal show={showResourceModal} fullscreen={'xxl-down'} onHide={() => setShowResourceModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Select the Resource</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">Type in Keyword to Filter: <input type="text" value={keyword} onChange={e => setKeyword(e.target.value)}/></div>
          <div className="mb-4">Filter By Resource Type:
          <select value={resourceType} onChange={e => setResourceType(e.target.value)}>
            <option value=""></option>
            <option value="doctor">Doctor</option>
            <option value="pharmacy">Pharmacy</option>
            <option value="hospital">Hospital</option>
            <option value="surgical center">Surgical Center</option>
            <option value="government agency">Government Agency</option>
            <option value="other">Other</option>
          </select>
          </div>
          
          <table className="personnel-info-table mb-4 medical"> 
            <thead>
              <tr className="sortable-header">
                <th>Name</th>
                <th>Specialty</th>
                <th>Address</th>
                <th>Office Name</th>
                <th>Branch Name</th>
                <th>City</th>
                <th>State</th>
                <th>Zipcode</th>
                <th></th>
              </tr>
              
            </thead>
            <tbody>
              {
                resources?.filter(r=> r.status === 'active')?.filter((item => filterResourceListByKeyword(item)))?.filter(item => filterResourcesListBySpecialty(item)).map(resource => <tr key={resource.id}>
                  <td>{`${resource?.name || ''}${resource?.name ? '-': ''}${resource?.name_original || ''}${resource?.name_original ? '-': ''}${resource?.name_branch || ''}`}</td>
                  <td>{resource?.specialty}</td>
                  <td>{resource?.address}</td>
                  <td>{resource?.name_original}</td>
                  <td>{resource?.name_branch}</td>
                  <td>{resource?.city}</td>
                  <td>{resource?.state}</td>
                  <td>{resource?.zipcode}</td>
                  <td style={{'min-width': '220px'}}>{ resource?.id === currentResource?.id ? 'Selected' : (<Button variant="primary" className="me-2" size="sm" onClick={() => {setCurrentResource(resource); setShowResourceModal(false)}}>Select</Button>)}</td>
                </tr>)
              }
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowResourceModal(false)}>
            Complete And Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateEvent;